import { IBoard } from './boards';
import Api, { useFetchApi, useUpdateApi } from './index';

export interface IGroup {
  id: number;
  name: string;
  description: string;
  boards: IBoard[];
  internal: boolean;
  isAdminRecommended: boolean;
}

export interface IGroupsListRequest {
  name?: number;
  internal?: boolean;
  active?: boolean
}

export function useGroupsList() {
  return useFetchApi(
    async (query: IGroupsListRequest) => Api.post('/group/list', query),
    { items: [] }
  )
}

export function useGroupCreate(cb: any) {
  return useUpdateApi(
    async (data: IGroup) => Api.post('/group/create', data),
    cb
  )
}

export function useGroupUpdate(cb: any) {
  return useUpdateApi(
    async (data: IGroup) => Api.post('/group/update', data),
    cb
  )
}

export function useGroupDelete(cb: any) {
  return useUpdateApi(
    async (id: number) => Api.post('/group/delete', { id }),
    cb
  )
}
